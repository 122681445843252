import React from "react";
import Button from '@material-ui/core/Button';
import ThumbUp from '@material-ui/icons/ThumbUp';
import ThumbDown from '@material-ui/icons/ThumbDown';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

// import MailOutline from "@material-ui/icons/MailOutline";
import { makeStyles } from '@material-ui/core/styles';
import StartDateField from '../../components/start-date-field';
import EndDateField from '../../components/end-date-field';
import EventInstanceTitle from '../../components/event-instance-title';
import ConditionalField from '../../components/conditional-field';

import moment from 'moment';
import get from 'lodash/get';


import {
  TextField,
  SimpleShowLayout,
  Show,
  TopToolbar,
  EditButton,
  ReferenceField,
  NumberField,
  Labeled,
  useUpdate,
} from "react-admin";

import UserNameField from "../../components/user-name-field";
import BookingStatusField from '../../components/booking-status-field';
import ServiceNameField from '../../components/service-name-field';

const styles = {
  inline: { display: 'inline-block', marginRight: 5 },
  extraCard: { minWidth: 550, marginBottom: 10, marginTop: 10 },
}

const useStyles = makeStyles(styles);


const ModificationField = ({ record, originalSource, newSource, label, fromFormat, toFormat }) => {
  console.log(`modification`, record);
  const original = get(record, originalSource);
  const modified = get(record, newSource);
  if (original === modified) {
    return <></>
  }
  return <Labeled label={label} fullWidth><span><span style={{ textDecoration: 'line-through' }}>{moment(original, fromFormat).format(toFormat)}</span> <span style={{ color: 'red' }}>{moment(modified, fromFormat).format(toFormat)}</span><br /></span></Labeled>;
};

const ModificationInfo = (props) => {
  const { record } = props;
  const classes = useStyles();
  const { event, exceptionDate, startTime, endTime, startDate } = record || { event: {} };
  console.log('exception date is', exceptionDate);
  if (!exceptionDate || (startTime === event.startTime && endTime === event.endTime && startDate === exceptionDate)) {
    return <></>;
  }
  return (
    <Card raised={true} className={classes.extraCard}>
      <CardHeader title="Session modified from original booking" />
      <CardContent>
        <ModificationField label="Date" originalSource="exceptionDate" newSource="startDate" fromFormat="YYYY-MM-DD" toFormat="LL" {...props} / >
        <ModificationField label="Start Time" originalSource="event.startTime" newSource="startTime" fromFormat="HH:mm" toFormat="h:mma" {...props}/>
        <ModificationField label="End Time" originalSource="event.endTime" newSource="endTime" fromFormat="HH:mm" toFormat="h:mma" {...props} />
      </CardContent>
    </Card>
  );
}


const ApproveButton = ({ record }) => {
  const [approve, { loading }] = useUpdate('EventInstance', record.id, {id: record.id, event: {...(record.event || {}), isPendingConfirmation: false }}, record);
  return (<Button color="primary" size="small" onClick={approve} disabled={loading}>
      <ThumbUp fontSize="small" style={{ color: 'green', paddingRight: '0.5em' }}/> Approve Booking
    </Button>
  );
};
const RejectButton = ({ record }) => {
  const [reject, { loading }] = useUpdate('EventInstance', record.id, {id: record.id, event: {...(record.event || {}), isPendingConfirmation: false, isCancelled: true }}, record);
  return (<Button color="primary" size="small" onClick={reject} disabled={loading}>
      <ThumbDown fontSize="small" style={{ color: 'red', paddingRight: '0.5em' }}/> Reject Booking
    </Button>
  );
};

const ApproveModificationButton = ({ record }) => {
  const [approve, { loading }] = useUpdate('EventInstance', record.id, {id: record.id, isPendingConfirmation: false}, record);
  return (<Button color="primary" size="small" onClick={approve} disabled={loading}>
      <ThumbUp fontSize="small" style={{ color: 'green', paddingRight: '0.5em' }}/> Approve Modification
    </Button>
  );
};
const RejectModificationButton = ({ record }) => {
  const [reject, { loading }] = useUpdate('EventInstance', record.id, {id: record.id, isPendingConfirmation: false, isCancelled: true }, record);
  return (<Button color="primary" size="small" onClick={reject} disabled={loading}>
      <ThumbDown fontSize="small" style={{ color: 'red', paddingRight: '0.5em' }}/> Reject Modification
    </Button>
  );
};

const EventShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    { data && data.isPendingConfirmation && (
      data.event.isPendingConfirmation ? <>
        <ApproveButton record={data} />
        <RejectButton record={data} />
      </> : <>
        <ApproveModificationButton record={data} />
        <RejectModificationButton record={data} />
      </>) }
    {/* <Button color="primary" size="small">
      <MailOutline fontSize="small" /> Send Provider
    </Button>
    <Button color="primary" size="small">
      <MailOutline fontSize="small" /> Send Customer
    </Button> */}
  </TopToolbar>
);

const RecurrenceInfo = ({ record }) => {
  const { event } = record;
  console.log(record);
  if (!event || !event.recurrence || Object.keys(event.recurrence).length === 0) {
    return 'Single Session';
  }
  const { startDate, endDate, weekdays = [] } = event;

  const { type, interval } = event.recurrence;
  if (type !== 'weekly') {
    throw new Error('unsupported recurrence type');
  }

  const remainingWeekdays = [...weekdays];
  const lastWeekday = remainingWeekdays.pop();
  const recurrenceString = interval > 1 ? ` ${interval} weeks on` : '';
  const weekdayString = weekdays.length > 1 ? `${remainingWeekdays.join(', ')} and ${lastWeekday}` : lastWeekday;
  let message = `Recurs every${recurrenceString} ${weekdayString} `;
  if (endDate && moment(endDate).isValid()) {
    message += `between ${moment(startDate).format('LL')} and ${moment(endDate).format('LL')}`;
  }
  else {
    message += `starting ${moment(startDate).format('LL')}`;
  }

  return message;
};


const EventShow = (props) => {
  const classes = useStyles();
  console.log('record is', props);
  return (
    <Show title={<EventInstanceTitle />} actions={<EventShowActions />} {...props}>
      <SimpleShowLayout>
        <ServiceNameField label="Service Name" className={classes.inline} />
        <BookingStatusField  className={classes.inline} />
        <ConditionalField comp={TextField} label="Title" source="title" />
        <ReferenceField label="Customer" link="show" source="customer.id" reference="Customer">
          <UserNameField />
        </ReferenceField>
        <ReferenceField label="Provider" link="show" source="provider.id" reference="Provider">
          <UserNameField />
        </ReferenceField>

        <StartDateField label="Start" />
        <EndDateField label="End" />
        <ConditionalField comp={TextField} label="Shared Notes (visible to customer and provider)" source="notes" />
        <ConditionalField comp={TextField} label="Dial-in Info" source="dialInInfo" />
        <ConditionalField comp={NumberField} options={{ style: 'currency', currency: 'USD' }} label="Hourly Price Override" source="hourlyPrice" />
        <ConditionalField comp={TextField} label="Payment Method" source="paymentMethod" />

        <RecurrenceInfo addLabel={true} label="Recurrence Info" />
        <ModificationInfo />
      </SimpleShowLayout>
    </Show>
  );
};

export default EventShow;
