const UserNameField = (props) => {
  const { record } = props;
  console.log('user name props', props);
  if (!record) {
    return '';
  }
  return `${record.firstName} ${record.lastName}`;
};

export default UserNameField;
