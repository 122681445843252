import React from 'react'
import {
  Show,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  TextField,
  ReferenceManyField,
  Datagrid,
  ShowButton,
  EmailField,
  DateField,
  BooleanField,
  ArrayField,
  Pagination,
  FunctionField,
} from 'react-admin'
import get from 'lodash/get';

import { makeStyles } from '@material-ui/core/styles';

import GeolocationField from '../../components/geolocation-field';
import ServiceNameField from '../../components/service-name-field';
import BookingStatusField from '../../components/booking-status-field';
import IdToArrayField from '../../components/id-to-array-field';

const styles = {
  inline: { display: 'inline-block' },
}

const useStyles = makeStyles(styles);

const UserName = ({ record }) => {
  return `${record.firstName} ${record.lastName}`;
};

const TruthyField = (props) => {
  const { record, source, invert } = props;
  const fieldName = `${source}-bool`;
  const val = get(record, source);
  record[fieldName] = invert ? !val : !!val;
  return <BooleanField {...props } source={fieldName} />
};

TruthyField.defaultProps = { addLabel: true }

const UserTitle = ({ record }) => {
  if (!record) return <span />;
  const { isAdmin, firstName, lastName, id, gender } = record;
  return <span>{isAdmin && (gender === 'female' ? '👸' : '🤴')} {firstName} {lastName} ({ isAdmin ? 'Admin' : 'Customer'} #{id})</span>;
};

// Opt-Out of SMS Reminders:
const UserShow = props => {
  const classes = useStyles();

  return (
    <Show title={<UserTitle />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basics">
          <TextField source="firstName" label="First Name" />
          <TextField source="lastName" label="Last Name" />
          <EmailField source="email" label="E-mail" />
          <TextField source="phoneNumber" label="Phone Number" />
          <TextField source="gender" label="Gender" />
          <DateField source="dateOfBirthTimestamp" label="Date of Birth" />
          <TextField source="billingMode" label="Billing Mode" />
          <TextField source="personalReferralCode" label="Referral Code" />
        </Tab>
        <Tab label="Matching Criteria">
          <TruthyField
            source="numDogs"
            label="Dogs"
            className={classes.inline}
          />
          <TruthyField
            source="numCats"
            label="Cats"
            className={classes.inline}
          />
          <TruthyField
            source="smokingHousehold"
            label="Smoking"
            className={classes.inline}
          />
          <TruthyField
            source="specialNeeds"
            label="Special Needs"
            className={classes.inline}
          />
          <TextField
            source="dietaryRestrictions"
            label="Dietary Restrictions"
          />
          <ArrayField source="children" label="Children">
            <Datagrid>
              <TextField source="name" label="Name" />
              <DateField source="birthdayTimestamp" label="Birthday (approx)" />
              <TextField source="gender" label="gender" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Address">
          <TextField label="Street Address" source="address.streetAddress" />
          <TextField label="Unit" source="address.unit" />
          <TextField label="City" source="address.city" />
          <TextField label="State" source="address.state" />
          <TextField label="Zipcode" source="address.zipcode" />
          <TextField
            label="Entrance Instructions"
            source="entranceInstructions"
          />
          <GeolocationField />
          <FunctionField
            label="Geolocation Link"
            render={({ geoLocation }) =>
              geoLocation ? (
                <a target="blank"
                  href={`https://maps.google.com/?q=${geoLocation.lat},${geoLocation.lng}`}
                >
                  See on the map
                </a>
              ) : (
                "-"
              )
            }
          />
        </Tab>
        <Tab label="Sessions">
          <ReferenceManyField
            pagination={<Pagination />}
            reference="EventInstance"
            target="customerId"
            addLabel={false}
            sort={{ field: "createdTimestamp", order: "DESC" }}
          >
            <Datagrid>
              <ReferenceField
                sortable={false}
                label="Provider"
                source="provider.id"
                reference="Provider"
                link="show"
              >
                <UserName />
              </ReferenceField>
              <TextField source="startDate" sortable={false} />
              <TextField source="startTime" sortable={false} />
              <TextField source="endDate" sortable={false} />
              <TextField source="endTime" sortable={false} />
              <DateField
                source="createdTimestamp"
                sortable={false}
                label="created"
              />
              <ServiceNameField />
              <BookingStatusField />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Contacts">
          <TextField source="partner.firstName" label="Partner First Name" />
          <TextField source="partner.lastName" label="Partner Last Name" />
          <TextField source="partner.email" label="Partner E-mail" />
          <TextField
            source="partner.phoneNumber"
            label="Partner Phone Number"
          />

          <ArrayField source="emergencyContacts" addLabel={false}>
            <Datagrid>
              <TextField source="name" label="Name" />
              <TextField source="relationship" label="Relationship" />
              <TextField source="phoneNumber" label="Phone Number" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Notifications">
          <TruthyField
            label="Appointment Text Reminders"
            source="notificationOptOut.appointmentReminders"
            invert={true}
          />
        </Tab>
        <Tab label="My Team">
          <IdToArrayField source="myTeam" addLabel={false}>
            <Datagrid addLabel={false}>
              <ReferenceField
                source="id"
                reference="Provider"
                addLabel={false}
                link="show"
                label="Provider"
              >
                <UserName addLabel={false} />
              </ReferenceField>
            </Datagrid>
          </IdToArrayField>
        </Tab>
        <Tab label="Admin Notes">
          <TextField source="adminNotes" label="Admin Notes" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export default UserShow
