import React from "react";
import {
  Datagrid,
  List,
  Filter,
  DateInput,
  TextField,
} from "react-admin";
import PaginationOptions from '../../components/pagination-options';

const PayrollListFilter = [
  <DateInput source="startDate" label="Start Date" alwaysOn/>,
  <DateInput source="endDate" label="End Date" alwaysOn />
];

const PayrollList = (props) => {
  return (
    <List
      {...props}
      pagination={<PaginationOptions />}
      perPage={25}
      title="Payroll"
      filters={PayrollListFilter}
    >
    <Datagrid>
            <TextField source="firstName" label="First Name" />
            <TextField source="lastName" label="Last Name"/>
            <TextField source="email" label="Email"/>
            <TextField source="seviceName" label="Service Name"/>
            <TextField source="takeHomeRate" label="Take home rate"/>
            <TextField source="hourlyRate" label="Hourly rate"/>
            <TextField source="totalhours" label="Total hours"/>
            <TextField source="totalTips" label="Total tips"/>
            <TextField source="totalLateSessions" label="Total late sessions"/>
            <TextField source="totalpayments" label="Total"/> 
    </Datagrid>
    </List>
  );
};

export default PayrollList;
