import React, { createElement } from 'react';
import { Labeled } from "react-admin";
import get from 'lodash/get';

const ConditionalField = (props) => {
  const { comp, record, children, source, label } = props;
  let { condition } = props;
  if (!condition) {
    condition = (record) => get(record, source);
  }
  if (!condition(record)) {
    return <span addLabel={false} />;
  }

  const wrapped = createElement(comp, { addLabel: true, ...props}, children);
  return <Labeled label={label}>{wrapped}</Labeled>;

};

ConditionalField.defaultProps = {
  addField: true,
};

export default ConditionalField;
