import React from 'react'
import { useInput } from "react-admin";
import { TextField } from '@material-ui/core'

const metersInMile = 1609.34

const OperationRadiusInput = ({ source }) => {
  const { input } = useInput({ source });
  const value = ((input.value || 0) / metersInMile).toFixed(3)

  return (
    <TextField
      label="Operation radius"
      variant="filled"
      type="number"
      defaultValue={value}
      onChange={(event) => input.onChange(event.target.value * metersInMile)}
    />
  );
};

export default OperationRadiusInput;