import React from 'react'
import Chip from '@material-ui/core/Chip'
import Help from "@material-ui/icons/Help";
import Cancel from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";

const BookingStatusField = ({ record, ...props }) => {
  let status = "Confirmed";
  let icon = <CheckCircle />;
  if (!record) {
    return <></>;
  }
  if (record.isCancelled) {
    status = "Cancelled";
    icon = <Cancel />;
  }
  else if (record.isPendingConfirmation) {
    status = "Pending";
    icon = <Help />;
  }
  return (
    <span {...props}><Chip label={status} icon={icon}/></span>
  )
};

export default BookingStatusField;
