
export const serviceNameList = [
  { id: 'childcare', name: 'Childcare' },
  { id: 'childcarePlus', name: 'Enrichment' },
  { id: 'virtual', name: 'Virtual' },
  { id: 'night_nurse', name: 'Night Nurse' },
  { id: 'cleaning', name: 'Cleaning' },
  { id: 'cooking', name: 'Cooking' },
  { id: 'housekeeping', name: 'Housekeeping' },
];

export const eventTypeList = [
  { id: 'appointment', name: 'Booking' },
  { id: 'interview', name: 'Interview' },
  { id: 'private', name: 'Provider\'s Personal Event' },
];

export const enrichmentActivitiesList = [
  { id: 'tutoring', name: 'Tutoring' },
  { id: 'artsAndCrafts', name: 'Arts and Crafts' },
  { id: 'musicAndDancing', name: 'Music and Dancing' },
  { id: 'acting', name: 'Acting' },
  { id: 'sports', name: 'Sports' },
];
