import React from 'react'
import {
  Create,
} from 'react-admin'
import CreateEditForm from './CreateEditForm';

const EventCreate = (props) => (
  <Create {...props}>
    <CreateEditForm  redirect="show"/>
  </Create>
)

export default EventCreate;
