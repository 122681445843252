import qs from 'querystring'

const fetchApi = async (url, userConfig = {}) => {
  const {
    publicApi,
    DOMAIN = process.env.REACT_APP_API_URL,
    ...config
  } = userConfig

  const defaults = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  }

  const token = localStorage.getItem('apiari_token')
  if (token && !publicApi) {
    defaults.headers.Authorization = `Token ${token}`
  }

  try {
    const resp = await fetch(
      `${DOMAIN}${url}`,
      Object.assign({}, defaults, config),
    )
    const [OK, data] = await Promise.all([resp.ok, resp.json()])
    if (OK) return { data }
    await Promise.reject(data)
    return data
  } catch (error) {
    await Promise.reject(error)
    return error
  }
}

const methods = {
  get(url, { params, ...config } = {}) {
    return fetchApi(!params ? url : `${url}?${qs.stringify(params)}`, config)
  },
  post(url, body, config = {}) {
    return fetchApi(url, {
      method: 'POST',
      body: JSON.stringify(body),
      ...config,
    })
  },
  put(url, body, config = {}) {
    return fetchApi(url, {
      method: 'PUT',
      body: JSON.stringify(body),
      ...config,
    })
  },
  patch(url, body, config = {}) {
    return fetchApi(url, {
      method: 'PATCH',
      body: JSON.stringify(body),
      ...config,
    })
  },
  delete(url, config = {}) {
    return fetchApi(url, { method: 'DELETE', ...config })
  },
}

export default methods
