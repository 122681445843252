import moment from 'moment';

const EndDate = ({ record }) => {
  if (!record) {
    return null;
  }
  return moment(`${record.startDate} ${record.startTime}`).add(record.hours, 'hours').format('LL h:mma');
};
EndDate.defaultProps = {
  addLabel: true,
};
export default EndDate;
