import React from 'react';

const eventTypeToHuman = {
  interview: "Interview",
  appointment: "Session",
  private: "Private Appointment",
}

const EventInstanceTitle = ({ record }) => {
  if (!record) {
    return <></>;
  }
  return <span>{eventTypeToHuman[record.eventType]} Details</span>;
};

export default EventInstanceTitle;
