import React from 'react'
import {
  TextInput,
  RadioButtonGroupInput,
  DateInput,
  BooleanInput,
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { makeStyles } from '@material-ui/core/styles';

const UserNameSelectArrayInput = (props) => {
  const { choices, input = {} } = props;
  const mappedChoices = choices.map(c => ({ id: c.id, name: `${c.firstName} ${c.lastName}` }))
  input.value = (input.value || []).map(v => `${v}`);
  return <SelectArrayInput {...props} input={input} source="myTeam" optionText="name" choices={mappedChoices} label="My Team"/>
};

const styles = {
  twoColLeft: { display: 'inline-block' },
  twoColRight: { display: 'inline-block', marginLeft: 32 },
}

const useStyles = makeStyles(styles);

const dateToTimestamp = (date) => {
  return +new Date(date);
}

const boolToNum = (bool) => bool ? 1 : 0;

const UserForm = (props) => {
  const classes = useStyles();
  const { isEdit } = props;

  return (
    <TabbedForm {...props}>
      <FormTab label="Basics">
        <TextInput source="firstName" formClassName={classes.twoColLeft} label="First Name" />
        <TextInput source="lastName" formClassName={classes.twoColRight} label="Last Name" />
        { !isEdit && <TextInput source="email" label="E-mail" /> }
        <TextInput source="phoneNumber" label="Phone Number" />
        <RadioButtonGroupInput source="gender" choices={[
          { id: 'male', name: 'Male' },
          { id: 'female', name: 'Female' },
        ]} />
        <DateInput source="dateOfBirthTimestamp" parse={dateToTimestamp} />
        <RadioButtonGroupInput source="billingMode" choices={[
          { id: 'cc', name: 'Credit Card' },
          { id: 'direct', name: 'Direct' },
        ]} />
        <Card style={{ backgroundColor: '#ffa7a1' }}>
          <CardContent>
            <BooleanInput source="isAdmin" label="Is Admin?"/>
          </CardContent>
        </Card>
      </FormTab>

      <FormTab label="Matching Criteria">
        <BooleanInput source="numDogs" label="Dogs" parse={boolToNum}/>
        <BooleanInput source="numCats" label="Cats" parse={boolToNum} />
        <BooleanInput source="smokingHousehold" label="Smoking Household" />
        <BooleanInput source="specialNeeds" label="Children With Special Needs" />
        <TextInput source="dietaryRestrictions" label="Dietary Restrictions"/>
        <ArrayInput source="children" label="Children">
          <SimpleFormIterator>
            <TextInput source="name" label="Name"/>
            <DateInput source="birthdayTimestamp" label="Birthday"/>
            <RadioButtonGroupInput source="gender" choices={[
              { id: 'male', name: 'Male' },
              { id: 'female', name: 'Female' },
            ]} label="Gender" />
          </SimpleFormIterator>
        </ArrayInput>

      </FormTab>
      <FormTab label="Address">
        <TextInput source="address.streetAddress" label="Street Address" formClassName={classes.twoColLeft} />
        <TextInput source="address.unit" label="Unit" formClassName={classes.twoColRight}/><br />
        <TextInput source="address.city" label="City" formClassName={classes.twoColLeft}/>
        <TextInput source="address.state" label="State" formClassName={classes.twoColRight}/>
        <TextInput source="address.zipcode" label="Zipcode"/>
        <TextInput source="entranceInstructions" label="Entrance Instructions" fullWidth />
      </FormTab>
      <FormTab label="Contacts">
      <TextInput source="partner.firstName" label="Partner First Name"/>
      <TextInput source="partner.lastName" label="Partner Last Name"/>
      <TextInput source="partner.email" label="Partner Email"/>
      <TextInput source="partner.phoneNumber" label="Partner Phone Number"/>

      <ArrayInput source="emergencyContacts">
        <SimpleFormIterator>
          <TextInput source="name" label="Name"/>
          <TextInput source="relationship" label="Relationship"/>
          <TextInput source="phoneNumber" label="Phone Number"/>
        </SimpleFormIterator>
      </ArrayInput>

      </FormTab>
      <FormTab label="Notifications">
        <RadioButtonGroupInput label="Appointment Text Reminders" source="notificationOptOut.appointmentReminders" choices={[
          { id: true, name: 'Opt Out' },
          { id: false, name: 'Opt In' },
        ]}/>
      </FormTab>
      <FormTab label="My Team">
        <ReferenceArrayInput source="myTeam" reference="Provider" label="My Team">
          <UserNameSelectArrayInput />
        </ReferenceArrayInput>
      </FormTab>
      <FormTab label="Admin Notes">
        <TextInput fullWidth multiline source="adminNotes" label="Admin Notes" />
      </FormTab>
    </TabbedForm>
  );
}

export default UserForm
