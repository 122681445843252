import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { TOKEN_KEY } from './auth/provider'

const uri = process.env.REACT_APP_GRAPHQL_URI

const httpLink = createHttpLink({ uri });

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    Authorization: localStorage.getItem(TOKEN_KEY),
    'x-api-key': process.env.REACT_APP_GRAPHQL_API_KEY,
  },
}));

const graphQlClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

export default graphQlClient
