import React from 'react'
import {
  Create,
} from 'react-admin'
import CreateEditForm from './CreateEditForm';

// const UserEditActions = ({ basePath, data, resource }) => (
//   <TopToolbar>
//     <ShowButton basePath={basePath} record={data} />
//   </TopToolbar>
// )

const UserCreate = (props) => (
  <Create {...props}>
    <CreateEditForm redirect="show" isEdit={false}/>
  </Create>
)

export default UserCreate
