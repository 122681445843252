import React from 'react'
import { useForm } from 'react-final-form';

import {
  // SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  DateInput,
  BooleanInput,
  TabbedForm,
  NumberInput,
  FormTab,
  CheckboxGroupInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceArrayInput,
  SelectArrayInput,
  ImageInput,
  ImageField,
  FormDataConsumer,
  Labeled,
} from 'react-admin'

import { makeStyles } from '@material-ui/core/styles';
import { serviceNameList, enrichmentActivitiesList } from '../../utils';
import OperationRadiusInput from '../../components/operation-radius-input'

const styles = {
  twoColLeft: { display: 'inline-block' },
  twoColRight: { display: 'inline-block', marginLeft: 32 },
}

const useStyles = makeStyles(styles);

const dateToTimestamp = (date) => {
  return +new Date(date);
}

let profilePicUpdatedHook = null;
const uploadToS3 = async (file) => {
  // const [file] = files;
  const initiateResponse = await fetch(`${process.env.REACT_APP_API_URL}user/123/photos/initiate-upload?contentType=${file.type}`, {
    method: 'post',
  });
  const { writeUrl, publicUrl } = await initiateResponse.json();
  console.log('upload url is', writeUrl);
  const headers = new Headers();
  headers.append('x-amz-security-token',
    'IQoJb3JpZ2luX2VjECwaCXVzLWVhc3QtMSJIMEYCIQDN5AMd6Wd3Wv8bqE%2FQR1Irq%2F%2BiIA6dTMAD7uoAGIXnWAIhAJe%2BZK88OZrnKq7asYE0Hk99ebU4DvZnsw7Ldu7v9bXgKuIBCDUQAhoMMjQ0MTM5MzY2NjIzIgx3m%2FnmS7wdO62TSw4qvwHEUY5pJoYWLbNBq7G4bHI6LakyEobyu7tiskWQn9zZPcXNPrTQ1OLUv15PM25u4g86IvCgQENMPhNFjhLrU95Ks1w8c%2F%2Bagrw%2FHdVgw%2BLwfxKA5f0ebxXMxU%2Ff20v0ZbUTFJ8vIwK5HEFRK53kmQr%2BNnr7M2PWh2bGhbWgD4%2FFysvplpyBifQ6ULFiOAO%2BkBVIjVR5JBm614MJK%2BrDATIattSFp9W3foD%2Fbi4kMNOiJF0epIEIzv16okempoQmYjD%2BuN%2F6BTrfAT8jQzecTgpjYKpsgVL6fGYzgNlBdx6TqQKQZZ3Thh6mVCxQ8vx6icN%2BKJn4vWuzbZ3if1%2BdTWhitW7ZDovBekMrbPaudNw9zyjRIVz3b5X1v%2F8eh4zgx3svMPYrDkWfWEk%2FFcheMTmjDvHEJ5tYKT6fX0W608zZ2HmmI0qYwNlYkJFBckFAmS8IE0iuieSdp8wtWhu6x7V%2F0aqjqC1rxq6LWudOwITflPmmj4QD8hR5Enb53gVLcRN%2FsDwDhDLIV13ZVbbpCusHp0B4XMlVVx7kRHyoROXAiVIQcfQy6Ow%3D');
  headers.append('Content-Type', file.type)
  // const formData = new FormData();
  // formData.append()
  const response = await fetch(writeUrl, {
    method: 'put',
    headers,
    body: file,
  });
  if (profilePicUpdatedHook) {
    console.log('calling url updated hook');
    profilePicUpdatedHook(publicUrl);
  }
  // record.profilePic = publicUrl;
  // record.hasPublicProfile = true;
  // console.log('new record is', record);
  console.log(response);
}


const UserForm = (props) => {
  const { record, profilePicUpdated, isEdit } = props;
  if (record && record.profilePic) {
    profilePicUpdated(record.profilePic);
  }
  profilePicUpdatedHook = profilePicUpdated;
  // const ths = this;
  console.log('props', props);
  const classes = useStyles();

  return (
    <TabbedForm {...props}>
      <FormTab label="Basics">
        <ImageInput source="profilePic" label="Photo" accept="image/*" onChange={uploadToS3}>
          <ImageField source="profilePic" title="Photo" />
        </ImageInput>
        <FormDataConsumer>
            {({formData, dispatch, ...rest}) => {
                if (!formData.src) {
                    return (
                        <div>
                        <Labeled label="Original image">
                            <ImageField source="profilePic" {...rest}/>
                        </Labeled>
                        </div>
                    );
                }
            }}
        </FormDataConsumer>

        <BooleanInput source="isActive" label="Active?" />
        <TextInput source="firstName" formClassName={classes.twoColLeft} label="First Name" />
        <TextInput source="lastName" formClassName={classes.twoColRight} label="Last Name" />
        { !isEdit && <TextInput source="email" label="E-mail" /> }
        <TextInput source="phoneNumber" label="Phone Number" />
        <RadioButtonGroupInput source="gender" choices={[
          { id: 'male', name: 'Male' },
          { id: 'female', name: 'Female' },
        ]} />
        <DateInput source="dateOfBirthTimestamp" parse={dateToTimestamp} />

        <hr />
        <TextInput source="zelleEmail" label="Zelle E-mail" />
        <TextInput source="zellePhoneNumber" label="Zelle Phone Number"/>
      </FormTab>

      <FormTab label="Profile">
        <CheckboxGroupInput label="Services" source="services" choices={serviceNameList} />
        <CheckboxGroupInput label="Enrichment Activities" source="enrichmentActivities" choices={enrichmentActivitiesList} />

        <TextInput multiline fullWidth source="blurbs.childcare" label="Blurb (Childcare)"/>
        <TextInput multiline fullWidth source="blurbs.childcarePlus" label="Blurb (Enrichment)"/>
        <TextInput multiline fullWidth source="blurbs.virtual" label="Blurb (Virtual)"/>
        <TextInput multiline fullWidth source="blurbs.cleaning"  label="Blurb (Cleaning)"/>
        <TextInput multiline fullWidth source="blurbs.nightNurse"  label="Blurb (Night Nurse)"/>
        <TextInput multiline fullWidth source="blurbs.cooking"  label="Blurb (Cooking)"/>
        <TextInput multiline fullWidth source="blurbs.housekeeping"  label="Blurb (Housekeeping)"/>

        <TextInput multiline fullWidth source="about.childcare" label="About (Childcare)"/>
        <TextInput multiline fullWidth source="about.childcarePlus" label="About (Enrichment)"/>
        <TextInput multiline fullWidth source="about.virtual" label="About (Virtual)"/>
        <TextInput multiline fullWidth source="about.cleaning"  label="About (Cleaning)"/>
        <TextInput multiline fullWidth source="about.nightNurse"  label="About (Night Nurse)"/>
        <TextInput multiline fullWidth source="about.cooking"  label="About (Cooking)"/>
        <TextInput multiline fullWidth source="about.housekeeping"  label="About (Housekeeping)"/>

        <TextInput multiline fullWidth source="funFacts"  label="Fun Facts"/>

        <DateInput source="lastBackgroundCheck" label="Last Background Check" parse={dateToTimestamp} />

        {/* New for becoming-provider */}
        <TextInput multiline fullWidth source="extraService" label="Extra Help you can offer families" />

        <ArrayInput source="certificates">
          <SimpleFormIterator>
            <TextInput source="name" label="Name"/>
            <DateInput source="timestamp" label="Date" parse={dateToTimestamp}/>
          </SimpleFormIterator>
        </ArrayInput>

      </FormTab>
      <FormTab label="Matching Criteria">
        <BooleanInput source="allowsDogs" label="Dogs OK?" />
        <BooleanInput source="allowsCats" label="Cats OK?" />
        <BooleanInput source="allowsSmoking" label="Smoking OK?" />
        <BooleanInput source="hasCar" label="Has car?" />
        <BooleanInput source="hasSpecialNeedsExperience" label="Has Special Needs Experience" />
        <NumberInput source="childcareAgeRange.min" label="Childcare Min Age" formClassName={classes.twoColLeft} />
        <NumberInput source="childcareAgeRange.max" label="Childcare Max Age" formClassName={classes.twoColRight} />
        <NumberInput source="reliabilityScore" label="Reliabilty Score" />
        <OperationRadiusInput source="operationRadius" label="Operation radius"/>


        {/* New for becoming-provider */}
        {/*<LanguageNameArrayField source="speaksLanguages" label="Speaks Languages" />*/}
        {/*{<TextInput fullWidth multiline source="multipleSiblingsExperience" label="Care experience" />}*/}
      </FormTab>
      <FormTab label="Emergency Contacts">
      <ArrayInput source="emergencyContacts">
        <SimpleFormIterator>
          <TextInput source="name" label="Name"/>
          <TextInput source="relationship" label="Relationship"/>
          <TextInput source="phoneNumber" label="Phone Number"/>
        </SimpleFormIterator>
      </ArrayInput>

      </FormTab>
      <FormTab label="Admin Notes">
        <TextInput fullWidth multiline source="adminNotes" label="Admin Notes" />
      </FormTab>
    </TabbedForm>
  );
}

export default UserForm
