import React from 'react'
import {
  Edit,
} from 'react-admin'
import CreateEditForm from './CreateEditForm';

// const UserEditActions = ({ basePath, data, resource }) => (
//   <TopToolbar>
//     <ShowButton basePath={basePath} record={data} />
//   </TopToolbar>
// )

const UserEdit = (props) => (
  <Edit {...props} undoable={false}>
    <CreateEditForm isEdit={true} />
  </Edit>
)

export default UserEdit
