import React from 'react'
import { FunctionField, Labeled } from "react-admin";

const GeolocationField = (props) => {
  return (
    <Labeled label="Geolocation">
      <FunctionField
        render={({ geoLocation }) => geoLocation ? `lat: ${geoLocation.lat} lng: ${geoLocation.lng}` : "-"}
      />
    </Labeled>
  );
};

export default GeolocationField;
