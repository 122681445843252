import React from 'react'
import buildGraphqlProvider from 'ra-data-graphql-simple'
import graphQlClient from '../resources/graphql-client'

const pageSize = 1000

function dataProviderDecorator(dataProvider) {
  return function(fetchType, resource, params) {
    if (fetchType === "GET_LIST" && params?.pagination?.perPage > pageSize) {
      return new Promise(async (resolve, reject) => {
        const bulkResult = []

        async function getPerPage(page) {
          try {
            const result = await dataProvider(fetchType, resource, {
              ...params,
              pagination: { page, perPage: pageSize }
            })
            
            bulkResult.push(...result.data)

            if (result.data.length >= pageSize) {
              await getPerPage(page + 1)
            }
          } catch (err) {
            return reject(err)
          } 
        }

        await getPerPage(1)

        resolve({
          data: bulkResult,
          total: bulkResult.length,
        })
      })
    }

    return dataProvider(fetchType, resource, params)
  }
}

export default function useGraphql() {
  const [dataProvider, setDataProvider] = React.useState(null)

  React.useEffect(() => {
    buildGraphqlProvider({client: graphQlClient })
      .then(data => setDataProvider(() => dataProviderDecorator(data)))
  }, [])

  return dataProvider
}
