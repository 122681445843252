import React from 'react'
import { FunctionField, Labeled } from "react-admin";

const metersInMile = 1609.34

const OperationRadiusField = ({ labeled = true }) => {

  const field = (
    <FunctionField
      render={({ operationRadius }) =>
        operationRadius !== undefined
          ? `${(operationRadius / metersInMile).toFixed(1)} miles`
          : "-"
      }
    />
  );

  return labeled ? <Labeled label="Commute radius">{field}</Labeled> : field;
};

export default OperationRadiusField;