import React from 'react'
import {
  EditButton,
  Datagrid,
  DateField,
  List,
  TextField,
  TextInput,
  BooleanInput,
  CheckboxGroupInput,
  SelectInput,
  NumberInput,
  downloadCSV,
  TopToolbar,
  FilterButton,
  CreateButton,
  ExportButton,
} from 'react-admin'
import { format } from 'date-fns'
import jsonExport from 'jsonexport/dist';
import { serviceNameList, enrichmentActivitiesList } from '../../utils';
import PaginationOptions from '../../components/pagination-options';
import OperationRadiusField from '../../components/operation-radius-field';

const providerExporter = (posts) => {
  const postsForExport = posts.map((post, index) => {
    const { operationRadius, created, ...postForExport } = post;

    delete postForExport.worksInZipcodes

    return {
      index: index + 1,
      ...postForExport,
      operationRadius: `${(operationRadius / 1609.34).toFixed(1)} miles`,
      created: format(created, "MM/dd/yyyy"),
    };
  });

  jsonExport(postsForExport, {}, (err, csv) => {
    downloadCSV(csv, "providers");
  });
};

const postFilters = [
  <TextInput source="query" label="Provider Name" alwaysOn />,
  <SelectInput source="performsService" label="Service"  choices={serviceNameList} alwaysOn />,
  <BooleanInput source="isActive" label="Active" alwaysOn />,
  <BooleanInput source="isSubscriber" label="Subscriber" alwaysOn />,
  
  <TextInput label="E-mail" source="email" />,
  <TextInput label="Phone Number" source="phoneNumber" />,
  <BooleanInput label="Has Public (SaaS) Profile" source="hasPublicProfile" />,
  <BooleanInput label="Dogs OK?" source="allowsDogs" />,
  <BooleanInput label="Cats OK?" source="allowsCats" />,
  <BooleanInput label="Smoking OK?" source="allowsSmoking" />,
  <BooleanInput label="Has Car?" source="hasCar" />,
  <BooleanInput label="Has special needs experience?" source="hasSpecialNeedsExperience" />,
  <CheckboxGroupInput label="Enrichment Activities" source="enrichmentActivities" choices={enrichmentActivitiesList} />,
  <NumberInput min={0} max={18} label="Child Age" source="childAge" />,
];

const ProviderListActions = () => (
  <TopToolbar>
    <CreateButton />
    <FilterButton />
    <ExportButton maxResults={10000} />
  </TopToolbar>
);

const UserList = (props) => {
  return (
    <List
      {...props}
      pagination={<PaginationOptions />}
      perPage={25}
      filters={postFilters}
      filterDefaultValues={{ isActive: true, isSubscriber: false }}
      sort={{ field: "created", order: "DESC" }}
      exporter={providerExporter}
      actions={<ProviderListActions />}
    >
      <Datagrid rowClick="show">
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="email" label="E-mail" />
        <TextField source="address.zipcode" label="Zipcode" />
        <DateField source="created" label="Profile Created" />
        <TextField source="phoneNumber" />
        <OperationRadiusField source="Operation Raduis" labeled={false} />
        <TextField source="becomingProviderLastStep" />
        <EditButton />
      </Datagrid>
    </List>
  );
}

export default UserList
