import React from 'react'
import {
  Edit,
} from 'react-admin'
import CreateEditForm from './CreateEditForm';

// const UserEditActions = ({ basePath, data, resource }) => (
//   <TopToolbar>
//     <ShowButton basePath={basePath} record={data} />
//   </TopToolbar>
// )

let profilePic = null;
const UserEdit = (props) => (
  <Edit {...props} undoable={false} transform={x => ({...x, profilePic})}>
    <CreateEditForm profilePicUpdated={p => { profilePic = p }} isEdit={true}/>
  </Edit>
)

export default UserEdit
