import React from 'react'
import Chip from '@material-ui/core/Chip'

const CovidCommitmentField = ({ record, ...props }) => {
  if (!record) {
    return <></>;
  }
  const { covidCommitments } = record;
  if (covidCommitments && covidCommitments.length) {
    const lastCommitment = covidCommitments[covidCommitments.length - 1];
    return <span {...props}><Chip label={`Accepted ${lastCommitment.date}`} style={{ backgroundColor: '#5eb314' }} /></span>;
  }
  return <span {...props}><Chip label="Never" style={{ backgroundColor: '#ff2424' }}/></span>;
};

CovidCommitmentField.defaultProps = {
  addLabel: true,
};

export default CovidCommitmentField;
