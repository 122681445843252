import React from "react";
import { Admin, Resource, Layout, MenuItemLink, getResources } from "react-admin";
import useGraphql from "./hooks/use-graphql";
import authProvider from "./resources/auth/provider";
import eventComponents from "./resources/event";
import providerComponents from "./resources/provider";
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import DefaultIcon from '@material-ui/icons/ViewList';
import FaceIcon from '@material-ui/icons/Face';
import PeopleIcon from '@material-ui/icons/People';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import Payment from '@material-ui/icons/Payment';
import StatsIcon from '@material-ui/icons/GraphicEq'
import customerComponents from "./resources/customer";
import payrollComponents from "./resources/payroll";
import statsComponents from "./resources/stats";

const { REACT_APP_WEB_URL: webUrl } = process.env;
console.log('env', process.env)

const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  console.log(resources);
  return (
    <div>
      {resources.filter(r => !r.options || !r.options.hide).map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            (resource.options && resource.options.label) ||
            resource.name
          }
          leftIcon={
            resource.icon ? <resource.icon /> : <DefaultIcon />
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
      <MenuItemLink
        to="/"
        primaryText="Amy for Admin"
        leftIcon={<FaceIcon />}
        onClick={() => window.location.href = `${webUrl}/chat-bot?admin=1`}
        sidebarIsOpen={open}
        exact={true} />
      {isXSmall && logout}
    </div>
  );
};

const MyLayout = props => <Layout  {...props} menu={Menu} />

function App() {
  const graphQlProvider = useGraphql();
  if (!graphQlProvider) return null;

  return (
    <Admin layout={MyLayout} dataProvider={graphQlProvider} authProvider={authProvider} >
      <Resource
        name="EventInstance"
        options={{ label: "Sessions" }}
        icon={EventAvailableIcon}
        {...eventComponents}
      />
      <Resource
        name="Provider"
        options={{ label: "Providers" }}
        icon={PeopleIcon}
        {...providerComponents}
      />
      <Resource
        name="Customer"
        options={{ label: "Customers" }}
        icon={SupervisorAccountIcon}
        {...customerComponents}
      />
      <Resource
        name="Payroll"
        options={{ label: "Payroll" }}
        icon={Payment}
        {...payrollComponents}
      />
      <Resource
        name="Stats"
        options={{ label: "Provider Dashboard" }}
        icon={StatsIcon}
        {...statsComponents}
      />
      <Resource name="Location" options={{ hide: true }} />
    </Admin>
  );
}

export default App;
