import React from 'react';

import {
  ArrayField,
} from 'react-admin'

const IdToArrayField = (props) => {
  const { record, source } = props;
  const val = (record[source] || []).map(mt => ({ id: mt}));
  return (<ArrayField { ...props} record={ {...props.record, [source]: val }} />)
}
IdToArrayField.defaultProps = { addLabel: true }

export default IdToArrayField;
