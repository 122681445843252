import React from 'react'
import {
  Create,
} from 'react-admin'
import CreateEditForm from './CreateEditForm';

let profilePic = null;

const UserCreate = (props) => (
  <Create {...props}  transform={x => ({...x, profilePic})}>
    <CreateEditForm profilePicUpdated={p => profilePic = p} isEdit={false}/>
  </Create>
)

export default UserCreate
