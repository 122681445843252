import React from 'react'
import {
  Show,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  TextField,
  ReferenceManyField,
  Datagrid,
  DateField,
  EmailField,
  ShowButton,
  BooleanField,
  ArrayField,
  ImageField,
  Pagination,
  NumberField,
  FunctionField,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Chip } from '@material-ui/core'

import GeolocationField from '../../components/geolocation-field';
import LanguageNameField from '../../components/language-name-field';
import ServiceNameField from '../../components/service-name-field';
import BookingStatusField from '../../components/booking-status-field';
import ConditionalField from '../../components/conditional-field';
import CovidCommitmentField from '../../components/covid-commitment-field';
import OperationRadiusField from '../../components/operation-radius-field';
import ReviewsField from '../../components/reviews-field';

const { REACT_APP_WEB_URL: webUrl } = process.env;
const UserTitle = ({ record }) => {
  if (!record) return <span />;
  return <span> {record.firstName} {record.lastName} (Provider #{record.id})</span>;
};

const styles = {
  inline: { display: 'inline-block' },
  chip: { marginRight: 3 },
}

const useStyles = makeStyles(styles);

const FlattenedArrayField = (props) => {
  const { singleComp, record, source } = props;
  const array = record[source]
  if (typeof array === 'undefined' || array === null || array.length === 0) {
    return <div/>
  } else {
    return (
      <>
        {array.map(item => singleComp(item))}
      </>
    )
  }
}

const ChipArrayField = (props) => {
  const classes = useStyles();
  return (<FlattenedArrayField singleComp={(item) => <Chip className={classes.chip} label={item} key={item} />} { ...props} />)
}
ChipArrayField.defaultProps = { addLabel: true }

const LanguageNameArrayField = (props) => {
  const classes = useStyles();
  if(props.record.speaksLanguages == undefined) {
    props.record.speaksLanguages = ['en']
  }

  return (<FlattenedArrayField singleComp={(item) => <LanguageNameField className={classes.chip} key={item} languageName={item} />} { ...props} />)
}
LanguageNameArrayField.defaultProps = { addLabel: true }

const ServiceNameArrayField = (props) => {
  const classes = useStyles();
  return (<FlattenedArrayField singleComp={(item) => <ServiceNameField className={classes.chip} key={item} serviceName={item} />} { ...props} />)
}

const UserName = ({ record }) => {
  return `${record.firstName} ${record.lastName}`;
};

const DirectLink = ({ comp, record, path}) => {
  return `${webUrl}/user/${record.alias}${path ? `/${path}` : ''}`;
}

const AttachedLink = (props) => {
  const url = props.record[props.source];
  return (<a href={url} target="_blank">Open</a>);
}

const DebugField = (props) => {
  let record = { ...props.record };
  delete record.eventInstances;
  delete record.eventInstancesIds;

  return (<pre>{JSON.stringify(record, null, 2)}</pre>)
}

const UserShow = props => {
  const classes = useStyles();

  return (
    <Show title={<UserTitle />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basics">
          <ImageField source="profilePic" title="Photo" />
          <TextField source="firstName" label="First Name" />
          <TextField source="lastName" label="Last Name" />
          <EmailField source="email" label="E-mail" />
          <TextField source="phoneNumber" label="Phone Number" />
          <TextField source="gender" label="Gender" />
          <DateField source="dateOfBirthTimestamp" label="Date of Birth" />
          <ConditionalField comp={BooleanField} source="hasPublicProfile" label="Has Public (SaaS) Profile"/>
          <CovidCommitmentField label="COVID Commitment" />

          {/* New for becoming-provider */}
          <EmailField source="zelleEmail" label="Zelle E-mail" />
          <TextField source="zellePhoneNumber" label="Zelle Phone Number"/>
          <TextField source="becomingProviderLastStep" label="Last Step of Sign Up"/>

        </Tab>
        <Tab label="Profile">
          <ServiceNameArrayField source="services" label="Performs Services" />
          <ConditionalField comp={ChipArrayField} source="enrichmentActivities" label="Enrichment Activities" />
          <ConditionalField comp={TextField} source="blurbs.childcare" label="Blurbs (Childcare)"/>
          <ConditionalField comp={TextField} source="blurbs.childcarePlus" label="Blurbs (Enrichment)"/>
          <ConditionalField comp={TextField} source="blurbs.virtual" label="Blurbs (Virtual)"/>
          <ConditionalField comp={TextField} source="blurbs.cleaning"  label="Blurbs (Cleaning)"/>
          <ConditionalField comp={TextField} source="blurbs.nightNurse"  label="Blurbs (Night Nurse)"/>
          <ConditionalField comp={TextField} source="blurbs.cooking"  label="Blurbs (Cooking)"/>
          <ConditionalField comp={TextField} source="blurbs.housekeeping"  label="Blurbs (Housekeeping)"/>

          <ConditionalField comp={TextField} source="about.childcare" label="About (Childcare)"/>
          <ConditionalField comp={TextField} source="about.childcarePlus" label="About (Enrichment)"/>
          <ConditionalField comp={TextField} source="about.virtual" label="About (Virtual)"/>
          <ConditionalField comp={TextField} source="about.cleaning"  label="About (Cleaning)"/>
          <ConditionalField comp={TextField} source="about.nightNurse"  label="About (Night Nurse)"/>
          <ConditionalField comp={TextField} source="about.cooking"  label="About (Cooking)"/>
          <ConditionalField comp={TextField} source="about.housekeeping"  label="About (Housekeeping)"/>
          <ConditionalField comp={TextField} source="funFacts"  label="Fun Facts"/>
          <ConditionalField comp={DateField} source="lastBackgroundCheck" label="Last Background Check" />

          {/* New for becoming-provider */}
          <ConditionalField comp={TextField} source="extraService"  label="Extra Help you can offer families" />

          <ArrayField source="certificates">
            <Datagrid>
              <TextField source="name" label="Name" />
              <DateField source="timestamp" label="Date" />
            </Datagrid>
          </ArrayField>

        </Tab>


        <Tab label="Matching Criteria">
          <BooleanField source="allowsDogs" label="Dogs OK?" className={classes.inline} />
          <BooleanField source="allowsCats" label="Cats OK?" className={classes.inline} />
          <BooleanField source="allowsSmoking" label="Smoking OK?" className={classes.inline} />
          <BooleanField source="hasCar" label="Has Car?" className={classes.inline} />
          <BooleanField source="hasSpecialNeedsExperience" label="Has Special Needs Experience" className={classes.inline} />
          <ChipArrayField source="worksInZipcodes" label="Works in Zipcodes"/>
          <TextField source="childcareAgeRange.min" label="Minimum Age" className={classes.inline} />
          <TextField source="childcareAgeRange.max" label="Maximum Age" className={classes.inline} />
          {/* New for becoming-provider */}
          <LanguageNameArrayField source="speaksLanguages" label="Speaks Languages" />
          {/*<TextField source="multipleSiblingsExperience" label="Care experience" className={classes.inline} />*/}
          <TextField source="reliabilityScore" label="Reliability Score" className={classes.inline} />
          <Divider />
          <TextField label="Zipcode" source="address.zipcode" />
          <TextField label="State" source="address.state" />
          <FunctionField
            label="Commute time"
            render={({ desirableCommutingTime }) =>
              desirableCommutingTime ? (
                `${desirableCommutingTime} minutes`
              ) : (
                "-"
              )
            }
          />
          <OperationRadiusField />
          <GeolocationField />
          <FunctionField
            label="Geolocation Link"
            render={({ geoLocation }) =>
              geoLocation ? (
                <a target="blank"
                  href={`https://maps.google.com/?q=${geoLocation.lat},${geoLocation.lng}`}
                >
                  See on the map
                </a>
              ) : (
                "-"
              )
            }
          />
        </Tab>
        <Tab label="Sessions">
          <ReferenceManyField pagination={<Pagination />} reference="EventInstance" target="providerId" addLabel={false} sort={{ field: 'createdTimestamp', order: 'DESC' }}>
            <Datagrid>
              <ReferenceField sortable={false} label="Customer" source="customer.id" reference="Customer" link="show">
                <UserName />
              </ReferenceField>
              <TextField sortable={false} source="startDate" />
              <TextField sortable={false} source="startTime" />
              <TextField sortable={false} source="endDate" />
              <TextField sortable={false} source="endTime" />
              <DateField sortable={false} source="createdTimestamp" label="created" />
              <ServiceNameField sortable={false} />
              <BookingStatusField sortable={false} />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Rates">
          <ConditionalField comp={NumberField} options={{ style: 'currency', currency: 'USD' }} source="prices.childcare" label="Hourly Rate (Childcare)"/>
          <ConditionalField comp={NumberField} options={{ style: 'currency', currency: 'USD' }} source="prices.childcarePlus" label="Hourly Rate (Enrichment)"/>
          <ConditionalField comp={NumberField} options={{ style: 'currency', currency: 'USD' }} source="prices.virtual" label="Hourly Rate (Virtual)"/>
          <ConditionalField comp={NumberField} options={{ style: 'currency', currency: 'USD' }} source="prices.cleaning"  label="Hourly Rate (Cleaning)"/>
          <ConditionalField comp={NumberField} options={{ style: 'currency', currency: 'USD' }} source="prices.night_nurse"  label="Hourly Rate (Night Nurse)"/>
          <ConditionalField comp={NumberField} options={{ style: 'currency', currency: 'USD' }} source="prices.cooking"  label="Hourly Rate (Cooking)"/>
          <ConditionalField comp={NumberField} options={{ style: 'currency', currency: 'USD' }} source="prices.housekeeping"  label="Hourly Rate (Housekeeping)"/>
        </Tab>
        <Tab label="Emergency Contacts">
          <ArrayField source="emergencyContacts" addLabel={false}>
            <Datagrid>
              <TextField source="name" label="Name" />
              <TextField source="relationship" label="Relationship" />
              <TextField source="phoneNumber" label="Phone Number" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Links">
          <ConditionalField comp={DirectLink} label="Default (Shows Enrichment profile, asks for service when booking)" condition={(record) => record.services && record.services.includes('childcarePlus')}/>
          <ConditionalField comp={DirectLink} path="childcare" label="Childcare" condition={(record) => record.services && record.services.includes('childcare')}/>
          <ConditionalField comp={DirectLink} path="childcarePlus" label="Enrichment" condition={(record) => record.services && record.services.includes('childcarePlus')}/>
          <ConditionalField comp={DirectLink} path="virtual" label="Virtual" condition={(record) => record.services && record.services.includes('virtual')}/>
          <ConditionalField comp={DirectLink} path="cleaning" label="Cleaning" condition={(record) => record.services && record.services.includes('cleaning')}/>
          <ConditionalField comp={DirectLink} path="nightNurse" label="Night Nurse" condition={(record) => record.services && record.services.includes('nightNurse')}/>
          <ConditionalField comp={DirectLink} path="cooking" label="Cooking" condition={(record) => record.services && record.services.includes('cooking')}/>
          <ConditionalField comp={DirectLink} path="housekeeping" label="Housekeeping" condition={(record) => record.services && record.services.includes('housekeeping')}/>
        </Tab>
        <Tab label="Reviews">
            <ReviewsField source="reviews" />
        </Tab>
        <Tab label="Admin Notes">
          <TextField source="adminNotes" label="Admin Notes" />

          {/* New for becoming-provider */}
          <hr />
          <ConditionalField comp={AttachedLink} source="profilePic" label="Profile Pic" />
          <ConditionalField comp={AttachedLink} source="resume" label="Resume" />
          <ConditionalField comp={AttachedLink} source="identificationPhoto" label="ID Photo" />
          <ConditionalField comp={AttachedLink} source="CPRCertificate" label="CPR Certificate" />

          <ArrayField source="references">
            <Datagrid>
              <TextField source="name" label="Name" />
              <TextField source="email" label="Email" />
              <TextField source="phoneNumber" label="Phone Number" />
            </Datagrid>
          </ArrayField>

          <ConditionalField comp={DebugField} source="email" label="Debug" />
        </Tab>

      </TabbedShowLayout>
    </Show>
  );
}

export default UserShow
