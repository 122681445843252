import React from 'react'
import { FunctionField, Labeled } from "react-admin";
import { Divider } from '@material-ui/core'

const ReviewsField = ({ record }) => {
  return record.reviews.map((item) => (
    <>
      <p>
        <Labeled label="Customer Name">
          <FunctionField
            render={() =>
              item["clientUserName"] ? item["clientUserName"] : "-"
            }
          />
        </Labeled>
        <Labeled label="Added on">
          <FunctionField
            render={() =>
              item["createdTimestamp"] ? (new Date(+item["createdTimestamp"])).toDateString() : "-"
            }
          />
        </Labeled>
      </p>
      <p>
        <FunctionField
          render={() => (item["review"] ? item["review"] : "-")}
        />
      </p>
      <Divider />
    </>
  ));
};

export default ReviewsField