import moment from 'moment';
const StartDate = ({ record }) => {
  if (!record) {
    return null;
  }
  return `${moment(record.startDate).format('LL')} ${moment(record.startTime, 'HH:mm').format('h:mma')}`;
};

StartDate.defaultProps = {
  addLabel: true,
};

export default StartDate;
